$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    function generateShowModal()
    {
        $(document).find('body a[data-control=admin-modal-content-default][data-control-mode=open]').on('click', function(e){

            e.preventDefault();

            let url = $(this).attr('href');

            if($('.modal').length) {
                $('.modal').remove();
            }

            loadContentModalBackend(url);
        });
    }

    loadContentModalBackend = (url) => {
        $.ajax({
            type: 'get',
            url: url,
            contentType: "json",
            beforeSend: function (e) {

            },
            success: function (data) {
                $('body').append(data.html);

                var myModal = new bootstrap.Modal(document.getElementById('admin-modal-form'), {    
                    keyboard: false,
                    backdrop: 'static',
                    focus: true,
                    show: true
                });

                myModal.show();

                generateAjaxSubmit();

            },
            error: function (response) {
                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax('error', '', response.message, true, true, false);
            }

        });
    }

    function generateAjaxSubmit(){
        // Send Form Data Text and File
        $('#adminSubmitDefault').click(function( e ){
            e.preventDefault();
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                $('.modal').modal('hide');
                $('.modal-backdrop').remove();
                //icon, title, text, confirmButton, cancelCutton, timer

                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                _loadContentData($urlRedirect, $containerClass);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function validationImageBeforeUpload()
    {
         $(document).on('change', '#form-input-image', function(){

            var obj = $(this);
            var filePath = obj.val();
            var objName = obj.attr('name');

            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;

            if (!allowedExtensions.exec(filePath)) {
               _alertAjax('error', '', 'Jenis File tidak diijinkan', true, true, false);
               obj.value = '';
               return false;
            }else{
            
               // Image preview
               if (this.files && this.files[0]) {
                  var reader = new FileReader();
                  reader.onload = (e) => {
                    $('#photoPreview_'+objName).attr('src', e.target.result);
                  };
                     
                  reader.readAsDataURL(this.files[0]);
               }

            }

      });
    }

   function generateAjaxSubmitForm(){
         $(document).on('click', '#buttonSubmitForm', function( e ){
            e.preventDefault();
            // Send Form Data Text and File
            var $button = $(this);
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            var $urlRoute = $button.attr('url-route');
            var $containerClass = $button.attr('containerClass');

            $.ajax({
               type: $method,
               enctype: 'multipart/form-data',
               url: $url,
               data: data,
               processData: false,
               contentType: false,
               dataType: 'json',
               cache: false,
               beforeSend: function(e){
                     // console.log('beforeSend:'+e);
               },

            }).done( function(response){
               resetFeedback();
               //icon, title, text, confirmButton, cancelCutton, timer
               _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
               _loadContentData($urlRoute, $containerClass);

            }).fail( function(xhr, status, error){

               resetFeedback();
               getFeedback(xhr.responseJSON.errors);
            });

         });
   }

    function generateAjaxDelete(){
        
        $(document).on('click', '.ajax-delete', function(e){
            e.preventDefault();
            var button = $(this);
            var message = button.attr('title');

            let $url = button.attr('href');
            button.addClass('disabled');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                
                }).done( function(response){
                    button.removeClass('disabled');
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, false, false, 1500);

                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');

                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    function generateLogoutForm()
    {
        $('.logout-form').click( function( e ){
            e.preventDefault();

            var $button = $(this);
            var $url = $button.attr('href');

            $.ajax({
                type: 'POST',
                url: $url,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                $('.modal').modal('hide');
                //icon, title, text, confirmButton, cancelCutton, timer

                _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);

            });

        });
    }

    function generateAdminLogoutForm()
    {
        $('.admin-logout-form').click( function( e ){
            e.preventDefault();

            var $button = $(this);
            var $url = $button.attr('href');

            $.ajax({
                type: 'POST',
                url: $url,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                $('.modal').modal('hide');
                //icon, title, text, confirmButton, cancelCutton, timer

                _alert(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);

            });

        });
    }

    function generatePageContentView()
    {
        $(document).on('click', '.getPageContent', function(e){
            e.preventDefault();
            var $button = $(this);
            var $url = $button.attr('url');
            var $containerClass = $button.attr('containerClass');

            _loadContentData($url, $containerClass);
        });
    }

    function generateFormData()
    {
        $(document).on('click', '.viewFormAjax', function(e){
            e.preventDefault();
            
            var $button = $(this);
            let $url = $(this).attr('url');
            var $containerClass = $button.attr('containerClass');

            _loadContentData($url, $containerClass);
            
        });
    }

    _loadContentData = ($url, $containerClass) => {
        $.ajax({
            type: 'GET',
            url: $url,
            dataType: 'json',
            cache: false,
            beforeSend: function(e){
                // console.log('beforeSend:'+e);
            },

        }).done( function(response){
            //icon, title, text, confirmButton, cancelCutton, timer
            $('.'+$containerClass).html(response.html);

        }).fail( function(xhr, status, error){

            _alertAjax('error', '', error, true, true, false);
        });
    }

    function generateDeleteFormAjax(){
        
        $(document).on('click', '.deleteFormAjax', function( e ){
            e.preventDefault();
            var $button = $(this);
            var message = $button.attr('title');

            var $url = $button.attr('href');
            $button.addClass('disabled');

            var $urlRedirect = $button.attr('url-redirect');
            var $containerClass = $button.attr('containerClass');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                }).done( function(response){
                    $button.removeClass('disabled');
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
                    _loadContentData($urlRedirect, $containerClass);
                }).fail( function(xhr, status, error){
                    $button.removeClass('disabled');
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    function generateUpdateSubmitForm()
    {
        $('#update-button-submit').click( function( e ){
            e.preventDefault();
            var button = $(this);
            button.addClass('disabled');
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },
            }).done( function(response){
                resetFeedback();
                button.removeClass('disabled');

                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);


            }).fail( function(xhr, status, error){
                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
                button.removeClass('disabled');

            });

        });
    }

    function generateUpdateProfileInformation(){
        // Send Form Data Text and File
        $(document).on('click','#adminSubmitProfileInformation', function( e ){
            e.preventDefault();
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            var profileImage = $('.profile-image');
            var profileName = $('.profile-name');
            var profileEmail = $('.profile-email');

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();
                $('.modal').hide();
                $('.modal-backdrop').remove();
                //icon, title, text, confirmButton, cancelCutton, timer

                profileImage.attr('src', response.data.file_url);
                profileName.html(response.data.name);
                profileEmail.html(response.data.email);

                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateAjaxReloadAdditionalMedia(){
        // Send Form Data Text and File
        $(document).on('click', '#ajaxReloadAdditionalMedia', function(e){
            e.preventDefault();
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $submitForm.reset();

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();

                var htmlMedia = "";
                var no = 1;
                $.each(response.mediaSources, function(key, value){
                    htmlMedia += "<tr>";
                    htmlMedia += "<td>"+no+"</td>";
                    htmlMedia += "<td>"+value.file_media_source+"</td>";
                    if(value.group_content.group_menu.title_additional_media){
                        htmlMedia += "<td>"+value.title+"</td>";
                    }
                    if(value.group_content.group_menu.description_additional_media){
                        htmlMedia += "<td>"+value.description+"</td>";
                    }
                    htmlMedia += "<td><button href='"+window.url+"adminweb/admin-contents/"+value.group_content_id+"/"+value.uuid+"/remove-additional-media' class='btn btn-primary btn-sm ajax-delete-additional-media' title='anda yakin akan menghapus media ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                    htmlMedia += "</tr>";
                    no += 1;
                });

                $('.data-media').html(htmlMedia);

                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);


            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateAjaxDeleteAdditionalMedia(){
        
        $(document).on('click', '.ajax-delete-additional-media', function(e){
            e.preventDefault();
            var button = $(this);
            var message = button.attr('title');

            let $url = button.attr('href');
            button.addClass('disabled');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                
                }).done( function(response){
                    button.removeClass('disabled');

                    var htmlMedia = "";
                    var no = 1;
                    $.each(response.mediaSources, function(key, value){
                        htmlMedia += "<tr>";
                        htmlMedia += "<td>"+no+"</td>";
                        htmlMedia += "<td>"+value.file_media_source+"</td>";
                        if(value.group_content.group_menu.title_additional_media){
                            htmlMedia += "<td>"+value.title+"</td>";
                        }
                        if(value.group_content.group_menu.description_additional_media){
                            htmlMedia += "<td>"+value.description+"</td>";
                        }
                        htmlMedia += "<td><button href='"+window.url+"adminweb/admin-contents/"+value.group_content_id+"/"+value.uuid+"/remove-additional-media' class='btn btn-primary btn-sm ajax-delete-additional-media' title='anda yakin akan menghapus media ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                        htmlMedia += "</tr>";
                        no += 1;
                    });

                    $('.data-media').html(htmlMedia);
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, false, false, 1500);

                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');

                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    function generateAjaxReloadTeamMember(){
        // Send Form Data Text and File
        $(document).on('click', '#ajaxReloadTeamMember', function(e){
            e.preventDefault();
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $submitForm.reset();
            $('#form-input-model_has_team').val(null);
            $('#form-input-model_has_role').val(null);

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();

                var htmlTeam = "";
                $.each(response.modelHasTeams, function(key, value){
                    htmlTeam += "<tr>";
                    htmlTeam += "<td>"+value.team.name+"</td>";
                    htmlTeam += "<td>"+value.role.name+"</td>";
                    htmlTeam += "<td><button href='"+window.url+"adminweb/admin-users/"+value.role_id+"/"+value.model_id+"/"+value.team_id+"/remove-team-member' class='btn btn-primary btn-sm ajax-delete-team-member' title='anda yakin akan menghapus user dari team ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                    htmlTeam += "</tr>";
                });

                $('.data-team-members').html(htmlTeam);

                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);


            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateAjaxDeleteTeamMember(){
        
        $(document).on('click', '.ajax-delete-team-member', function(e){
            e.preventDefault();
            var button = $(this);
            var message = button.attr('title');

            let $url = button.attr('href');
            button.addClass('disabled');

            $('#form-input-model_has_team').val(null);
            $('#form-input-model_has_role').val(null);

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                
                }).done( function(response){
                    button.removeClass('disabled');

                    var htmlTeam = "";
                    $.each(response.modelHasTeams, function(key, value){
                        htmlTeam += "<tr>";
                        htmlTeam += "<td>"+value.team.name+"</td>";
                        htmlTeam += "<td>"+value.role.name+"</td>";
                        htmlTeam += "<td><button href='"+window.url+"adminweb/admin-users/"+value.role_id+"/"+value.model_id+"/"+value.team_id+"/remove-team-member' class='btn btn-primary btn-sm ajax-delete-team-member' title='anda yakin akan menghapus user dari team ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                        htmlTeam += "</tr>";
                    });

                    $('.data-team-members').html(htmlTeam);
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, false, false, 1500);

                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');

                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }
            
        });
    }

    function generateAjaxReloadTeamUserRole(){
        // Send Form Data Text and File
        $(document).on('click', '#ajaxReloadTeamUserRole', function(e){
            e.preventDefault();
            
            var $submitForm  = document.getElementById('admin-ajax-form');
            var data = new FormData($submitForm);

            var $method = $submitForm.getAttribute('method');
            var $url = $submitForm.getAttribute('action');

            $submitForm.reset();

            $.ajax({
                type: $method,
                enctype: 'multipart/form-data',
                url: $url,
                data: data,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){
                resetFeedback();

                var htmlTeam = "";
                $.each(response.teamHasRoles, function(key, value){
                    htmlTeam += "<tr>";
                    htmlTeam += "<td>"+value.user.name+"</td>";
                    htmlTeam += "<td>"+value.role.name+"</td>";
                    if((response.userRoleActive <= value.role.id) && (response.userActive != value.user.id)){
                        htmlTeam += "<td><button href='"+window.url+"adminweb/admin-teams/"+value.role_id+"/"+value.model_id+"/"+value.team_id+"/remove-user' class='btn btn-primary btn-sm ajax-delete-team-user-role' title='anda yakin akan menghapus user dan role dari team ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                    }
                    htmlTeam += "</tr>";
                });

                $('.data-team-user-roles').html(htmlTeam);

                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);


            }).fail( function(xhr, status, error){

                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    function generateAjaxDeleteTeamUserRole(){
        
        $(document).on('click', '.ajax-delete-team-user-role', function(e){
            e.preventDefault();
            var button = $(this);
            var message = button.attr('title');

            let $url = button.attr('href');
            button.addClass('disabled');

            if (confirm(message)) {
                $.ajax({
                    type: 'DELETE',
                    url: $url,
                    contentType: "application/json",
                    dataType: 'json',
                    cache: false,
                    beforeSend: function(e){
                        // console.log('beforeSend:'+e);

                    }
                
                }).done( function(response){
                    button.removeClass('disabled');

                    var htmlTeam = "";
                    $.each(response.teamHasRoles, function(key, value){
                        htmlTeam += "<tr>";
                        htmlTeam += "<td>"+value.user.name+"</td>";
                        htmlTeam += "<td>"+value.role.name+"</td>";
                        if((response.userRoleActive <= value.role.id) && (response.userActive != value.user.id)){
                            htmlTeam += "<td><button href='"+window.url+"adminweb/admin-teams/"+value.role_id+"/"+value.model_id+"/"+value.team_id+"/remove-user' class='btn btn-primary btn-sm ajax-delete-team-user-role' title='anda yakin akan menghapus user dan role dari team ini?'><i class='fa fa-minus-circle text-danger'></i> hapus</button></td>";
                        }
                        htmlTeam += "</tr>";
                    });

                    $('.data-team-user-roles').html(htmlTeam);
                    
                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(response.status, response.title, response.message, false, false, 1500);

                }).fail( function(xhr, status, error){
                    button.removeClass('disabled');

                    //icon, title, text, confirmButton, cancelCutton, timer
                    _alertAjax(status, '', error, true, true, false);
                });
            }else{

                button.removeClass('disabled');
                
            }
            
        });
    }

    function generateSwitchTeam()
    {
        $(document).on('click', '.switch-team', function(e){
            e.preventDefault();

            var obj = $(this);

            var url = obj.attr('href');

            $.ajax({
                type: 'GET',
                url: url,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },

            }).done( function(response){

                //icon, title, text, confirmButton, cancelCutton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);

                location.replace(window.url + 'adminweb/dashboard');

            }).fail( function(xhr, status, error){

                getFeedback(xhr.responseJSON.errors);
            });

        });
    }

    getFeedback = (errors) => {
        $.each(errors, function(error, e){
            $("input[name='"+error+"']").addClass('is-invalid');
            $('.error-'+error).html(e);
        });
    }

    resetFeedback = () => {
        $(".form-control").removeClass('is-invalid');
        $(".form-note").html('');
    }

    function getAjax(){
        $(document).on('click', '.get-ajax', function( e ){
            e.preventDefault();
            // Send Form Data Text and File
            var button = $(this);
            var url = button.attr('href');
  
            $.ajax({
                type: 'GET',
                enctype: 'multipart/form-data',
                url: url,
                processData: false,
                contentType: false,
                dataType: 'json',
                cache: false,
                beforeSend: function(e){
                    // console.log('beforeSend:'+e);
                },
  
            }).done( function(response){
                resetFeedback();
                //icon, title, text, confirmButton, cancelButton, timer
                _alertAjax(response.status, response.title, response.message, response.confirm, response.cancel, response.timer);
  
            }).fail( function(xhr, status, error){
  
                resetFeedback();
                getFeedback(xhr.responseJSON.errors);
            });
  
        });
    }

    function getDaterangepickerbutton()
    {
        $(document).on('click', '.show-button', function(e){
            var table = $('.datatable');

            table.on("preXhr.dt", function(e, settings, data){
                var dateRange = $('#daterange-btn').data('daterangepicker');
                data.start_date = dateRange.startDate.format('YYYY-MM-D');
                data.end_date = dateRange.endDate.format('YYYY-MM-D');
            });

            $('.datatable').DataTable().ajax.reload();
            return false;
        });

        $(document).on('click', '.clear-button', function(e){
            var table = $('.datatable');
            
            table.on("preXhr.dt", function(e, settings, data){
                data.start_date = '';
                data.end_date = '';
            });

            $('.datatable').DataTable().ajax.reload();
            return false;
        });
    }

    function getDaterangepickerExport()
    {
        $(document).on('click', '.export-button', function(e){
            e.preventDefault();
            var obj = $(this);
            obj.addClass('disabled');

            var url = obj.data('href');
            var dateRange = $('#daterange-btn').data('daterangepicker');
            var start_date = dateRange.startDate.format('YYYY-MM-D');
            var end_date = dateRange.endDate.format('YYYY-MM-D');
            
            var data = {
                'start_date' : start_date,
                'end_date' : end_date
            };

            $.ajax({
                url: url,
                type: 'POST',
                xhrFields: {
                    responseType: 'blob',
                },
                data: data,
                success: function (response, status, xhr){
                    obj.removeClass('disabled');

                    var disposition = xhr.getResponseHeader('content-disposition');
                    var matches = /"([^"]*)"/.exec(disposition);
                    var filename = (matches != null && matches[1] ? matches[1] : moment()+'.csv');

                    var blob = new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        });
    }

    function showImageFull()
    {
        $(document).on('click', '.show-full-image', function(){
            var obj = $(this);
            var fullImage = obj.data('src');
            $('.show-image').attr('src', fullImage);
            obj.addClass('minimize-image');
            obj.removeClass('show-full-image');
        });
    }

    function minimizeImage()
    {
        $(document).on('click', '.minimize-image', function(){
            var obj = $(this);
            var thumbImage = obj.data('srcthumb');
            $('.show-image').attr('src', thumbImage);
            obj.addClass('show-full-image');
            obj.removeClass('minimize-image');
        });
    }

    function readNotification()
    {
        $(document).on('click', '.my-notif', function(){
            var obj = $(this);
            var url = obj.data('url');
            var totalNotification = $('#totalNotification');

            $.ajax({
                type: 'POST',
                url: url,
                type: 'POST',
                dataType: "json",
                data: {
                    '_method': 'POST',
                }
            }).done( function(response){
                
                if(response.responseData.status){

                    obj.removeClass('unread');
                    totalNotification.html(response.responseData.total);

                }


            }).fail( function(xhr, status, error){
                _alertAjax('error', '', error, true, true, false);
            });
        });
    }

    bsCustomFileInput.init();
    
    generateAjaxSubmit();
    generateShowModal();
    generateAjaxDelete();
    generateDeleteFormAjax();

    generateLogoutForm();
    generateAdminLogoutForm();
    generatePageContentView();
    generateFormData();

    generateAjaxSubmitForm();

    generateUpdateSubmitForm();

    generateUpdateProfileInformation();

    generateAjaxReloadAdditionalMedia();
    generateAjaxDeleteAdditionalMedia();

    generateAjaxReloadTeamMember();
    generateAjaxDeleteTeamMember();

    generateAjaxReloadTeamUserRole();
    generateAjaxDeleteTeamUserRole();

    generateSwitchTeam();


    validationImageBeforeUpload();

    getAjax();

    getDaterangepickerbutton();
    getDaterangepickerExport();

    showImageFull();
    minimizeImage();
    readNotification();

});